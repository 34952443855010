enum Method {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
  OPTIONS = 'OPTIONS',
  HEAD = 'HEAD',
}

enum APIVersion {
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3',
}

/**
 * 权限的类型
 */
enum PermissionType {
  MENU = 1,
  BUTTON = 2,
}

/**
 * 平台类型
 */
enum Platform {
  // CBTI2.0 云医端
  CONSOLE = 1,
  // CBTI2.0 组织端
  ORGANIZATION = 2,
  // HX心理CT 云医端
  HXCONSOLE = 3,
  // HX心理CT 组织端
  HXORGANIZATION = 4,
  // CBTI2.0 量表优化 云医端
  XLCONSOLE = 5,
  // CBTI2.0 量表优化 组织端
  XLORGANIZATION = 6,
}

/**
 * 学历、性别、证件类型、婚姻状况
 */
const EducationDegree = ['', '小学以下', '小学', '初中', '高中', '中专', '大专', '本科', '硕士', '博士', '学龄前'];
const Gender = ['未知', '男', '女'];
const CredentialsType = ['', '身份证', '护照', '外国人永久居留（身份）证', '港澳往返内地通行证', '台湾往返内地通行证'];
const Marriage = ['', '未婚', '已婚', '离异', '丧偶', '<18岁不涉及', '其他'];
const TeamType = ['', '实名', '非实名'];
const ResidentType = ['未知', '城镇', '农村'];
/**
 * 量表特殊规则类型：MMPI-399-A、MMPI-399-B、PSQI-A、PSQI-B
 */
export enum QuestionnaireSpecialRulesEnum {
  MMPI_399_HOSPITAL = 'MMPI-399-A', // 院内版
  MMPI_399_SOCIAL = 'MMPI-399-B', // 社会版
  PSQI_HOSPITAL = 'PSQI-A', // 院内版
  PSQI_SOCIAL = 'PSQI-B', // 社会版
}

enum ScoreType {
  // 不计分
  NOSCORE = 0,
  // 计分(单因子)
  SINGLE = 1,
  // 维度(多因子)
  MULTIPLE = 2,
}
// 个人评测页面来源
enum EvaluationFrom {
  // 用户列表
  USER = 1,
  // 团队用户
  TEAM = 3,
  // 团队测评
  Evaluation = 2,
}
enum Template {
  EVALUATION = 0, // 测评报告模版
  DATA_COMPARISON = 1, // 数据对比模版
}

/**
 * 是否院内版
 */
enum SocialOrHospital {
  SOCIAL = 0,
  HOSPITAL = 1,
}

/**
 * 院内版登录身份
 */
enum InnerHospitalAccountType {
  USER = 0,
  PATIENT = 1,
}

/**
 * 患者登录类型: 1-登记号 2-账号 3-短信验证码
 */
enum PatientAccountType {
  REGNO = 1,
  ACCOUNT = 2,
  MESSAGE = 3,
}

/**
 * 测评类型
 */
// enum DoEvaluationType {
//   SELF = 1,
//   OTHER = 2,
//   DOCTOR = 3,
// }
const DoEvaluationType = ['', '患者自评', '知情人他评', '医生他评'];
const GenderIcon = ['', '/assets/gender/man.png', '/assets/gender/woman.png'];
/**
 * 角色数据范围
 */
enum RoleScopeType {
  ALL = 0,
  SELFDEPANDSUBDEP = 1,
  SELFDEP = 2,
  SELF = 3,
}
enum DataComparisonType {
  CUT = 0,
  Add = 1,
}
const EvaluationRecordStatusType = ['未测', '已测', '进行中', '失效'];
// 用户创建渠道
enum UserChannel {
  ONLINE = 1,
  TEAM = 2,
  INNERHOSPITAL = 3,
}
// 测评记录、预警测评渠道
enum EvaluationType {
  TEAM = 1,
  INNERHOSPITAL = 2,
  ONLINE = 3,
}

export {
  Method,
  APIVersion,
  PermissionType,
  Platform,
  EducationDegree,
  Gender,
  CredentialsType,
  Marriage,
  TeamType,
  ScoreType,
  EvaluationFrom,
  Template,
  SocialOrHospital,
  InnerHospitalAccountType,
  DoEvaluationType,
  ResidentType,
  GenderIcon,
  RoleScopeType,
  DataComparisonType,
  EvaluationRecordStatusType,
  UserChannel,
  EvaluationType,
  PatientAccountType,
};
